<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
// * -->
<template>
  <div>
    <div
      v-for="(iconAction, i) in iconsAction"
      v-show="
        (canAccess('UI_DEPLOY:LOG_RESET') &&
          iconAction === 'Retry' &&
          (tableItem.status === 'Failed' || tableItem.status === 'Error')) ||
        (canAccess('UI_DEPLOY:LOG_RESET') &&
          iconAction === 'DisabledRetry' &&
          tableItem.status !== 'Failed' &&
          tableItem.status !== 'Error') ||
        (canAccess('UI_DEPLOY:LOG_RESET') &&
          iconAction === 'MarkTaskAsFail' &&
          tableItem.status === 'Not responding') ||
        (canAccess('UI_DEPLOY:LOG_RESET') &&
          iconAction === 'MarkTaskAsSuccess' &&
          tableItem.status === 'Not responding') ||
        (canAccess('UI_DEPLOY:LOG_RESET') &&
          iconAction === 'DisabledMarkTaskAsFail' &&
          tableItem.status !== 'Not responding') ||
        (canAccess('UI_DEPLOY:LOG_RESET') &&
          iconAction === 'DisabledMarkTaskAsSuccess' &&
          tableItem.status !== 'Not responding')
      "
      :key="i"
      class="inline cursor-pointer"
    >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-img
            :id="`iiotDeployDetailsTableActionsResetAndFeedback${iconAction}Image_${index}`"
            :src="`/img/${iconAction}.svg`"
            v-bind="attrs"
            max-height="40px"
            max-width="40px"
            v-on="on"
            @click.native.stop="clickAction(iconAction)"
          />
        </template>
        <span>{{ $t(`defaultTable.${iconAction}`) }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconsAction: {
      type: Array,
      default: () => [],
    },
    fetchAction: {
      type: Function,
      default: () => {},
    },
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    customId: {
      type: String,
      default: 'table',
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    async clickAction(iconAction) {
      if (iconAction === 'Retry') {
        try {
          await this.$store.dispatch('deploy-details/reset_task', this.tableItem.id);
        } catch (e) {
          this.$log.debug(e);
        }
      }
      if (iconAction === 'MarkTaskAsFail') {
        try {
          await this.$store.dispatch('deploy-details/mark_task_as_fail', this.tableItem.id);
        } catch (e) {
          this.$log.debug(e);
        }
      }
      if (iconAction === 'MarkTaskAsSuccess') {
        try {
          await this.$store.dispatch('deploy-details/mark_task_as_success', this.tableItem.id);
        } catch (e) {
          this.$log.debug(e);
        }
      }
    },
  },
};
</script>

<style scoped>
.inline {
  display: inline-block;
}
</style>
